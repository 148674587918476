<template>
  <div id="FormInput">
    <div class="form-group">
      <label
        ><b>{{ lista.name }}</b></label
      >
      <div v-if="lista.type == 'file'">
        <b-form-file
          v-if="lista.required == true"
          :state="lista.value != null"
          :type="lista.type"
          browse-text="Buscar"
          :placeholder="lista.placeholder"
          :accept="lista.accept"
          v-model="lista.value"
          @change="previewImage"
          :required="lista.required"
          :disabled="lista.disable"
        />
        <b-form-file
          v-else
          :type="lista.type"
          browse-text="Buscar"
          :placeholder="lista.placeholder"
          :accept="lista.accept"
          v-model="lista.value"
          @change="previewImage"
          :required="lista.required"
          :disabled="lista.disable"
        />
      </div>
      <div v-else-if="lista.type == 'select'">
        <b-form-select
          v-if="lista.required == true"
          :state="lista.value != null"
          v-model="lista.value"
          :options="lista.data_detail"
          :required="lista.required"
          :disabled="lista.disable"
        />
        <b-form-select
          v-else
          v-model="lista.value"
          :options="lista.data_detail"
          :required="lista.required"
          :disabled="lista.disable"
        />
      </div>
      <div v-else-if="lista.type == 'area'">
        <b-form-textarea
          v-if="lista.required == true"
          :state="lista.value != null"
          :placeholder="lista.placeholder"
          v-model="lista.value"
          :required="lista.required"
          :disabled="lista.disable"
        />
        <b-form-textarea
          v-else
          :placeholder="lista.placeholder"
          v-model="lista.value"
          :required="lista.required"
          :disabled="lista.disable"
        />
      </div>
      <div v-else>
        <b-form-input
          v-if="lista.required == true"
          :state="lista.value != null"
          :type="lista.type"
          :placeholder="lista.placeholder"
          v-model="lista.value"
          :required="lista.required"
          :disabled="lista.disable"
        />
        <b-form-input
          v-else
          :type="lista.type"
          :placeholder="lista.placeholder"
          v-model="lista.value"
          :required="lista.required"
          :disabled="lista.disable"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { uuid } from "vue-uuid";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
export default {
  name: "FormInput",
  props: {
    lista: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      imageError: false,
      imageData: null,
      picture: null,
      uploadValue: 0,
    };
  },
  methods: {
    salida() {
      if (this.lista.value == undefined) {
        this.lista.value = null;
      }
      var inputValue = {
        id: this.lista.id,
        label: this.lista.value,
      };
      return inputValue;
    },
    requerido() {
      return this.lista.required;
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },
    onUpload() {
      this.picture = null;
      let nameUrl = null;
      nameUrl = uuid.v1();
      const storageRef = firebase
        .storage()
        //uuid genera claves unicas
        .ref(nameUrl)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
          this.imageError = true;
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.picture = url;
          });
        }
      );
      return nameUrl;
    },
    uploadImage() {
      if (this.imageData != null) {
        var value = this.onUpload();
        if (value != null) {
          return value;
        } else {
          return null;
        }
      }
    },
    //Se verifica la terminacion segun el nombre
    getFileExtension(arrTerm) {
      var dato = this.imageData.name.slice(
        ((this.imageData.name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      for (let index = 0; index < arrTerm.length; index++) {
        if (dato.toUpperCase() == arrTerm[index]) return false;
      }
      return true;
    },
    //Retorna el porcentaje de subida de imagenes
    getUploadValue() {
      return this.uploadValue;
    },
    getUploadError() {
      return this.imageError;
    },
  },
};
</script>