<template>
  <div id="dashboard">
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h1><b-icon icon="envelope" /> Trámite</h1>
        </div>
        <div class="card-body">
          <b-alert variant="info" show dismissible fade>
            <b
              >Realice el pago completo según el trámite que desea realizar, las
              siguientes imágenes te ayudaran a poder encontrar el número de
              operación.</b
            >
          </b-alert>
          <!--Imagenes vaucher-->
          <b-container fluid class="p-4 bg-muted mb-3">
            <b-row class="text-center">
              <b-col lg="4" sm="12">
                <b-img
                  thumbnail
                  fluid
                  src="https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/banco%20bcp.jpg?alt=media"
                  alt="Image 1"
                ></b-img>
              </b-col>
              <b-col lg="4" sm="12">
                <b-img
                  thumbnail
                  fluid
                  src="https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/banco%20nacion.jpg?alt=media"
                  alt="Image 2"
                ></b-img>
              </b-col>
              <b-col lg="4" sm="12">
                <b-img
                  thumbnail
                  fluid
                  src="https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/multired.jpg?alt=media"
                  alt="Image 3"
                ></b-img>
              </b-col>
            </b-row>
          </b-container>

          <div class="container col-lg-7 col-md-12">
            <div>
              <b-alert variant="success" show fade>
                <p>Información de descuentos:</p>
                <!--Información Descuentos-->
                <b-row class="mb-2">
                  <b-col lg="4" sm="12" class="my-1">
                    <div class="text-center">
                      <b-button class="des-boton" id="popover-target-1">
                        Pregrado UNSA
                      </b-button>
                      <b-popover
                        target="popover-target-1"
                        triggers="hover"
                        placement="left"
                      >
                        <template #title>Descuento</template>
                        <div class="text-center">
                          <h4>PRE GRADO – UNSA</h4>
                          <p><b>Matricula S/. 120.00</b></p>
                          <p><b>Pensiones S/. 180.00</b></p>
                          <p><b>Sin descuento S/. 200.00</b></p>
                          <p>
                            Presentar una solicitud dirigida a UNIDAD DE
                            PRODUCCIÓN DE BIENES Y SERVICIOS (UCPS).
                          </p>
                          <p>
                            Indicar sus datos completos, nombre del programa de
                            especialización y turno en el cual se matriculó.
                          </p>
                          <p>
                            Adjuntar Constancia de matrícula con sello de su
                            escuela profesional. Presentar su documento impreso
                            (con copia de cargo) y constancia respectiva en
                            Oficina UCPS, Av Venezuela 427 - A , 3er piso
                            (Edificio SUTUNSA) de lunes a viernes de 10:00 a
                            13:00 hrs.
                          </p>
                        </div>
                      </b-popover>
                    </div>
                  </b-col>
                  <b-col lg="4" sm="12" class="my-1">
                    <div class="text-center">
                      <b-button class="des-boton" id="popover-target-2">
                        Egresados UCPS
                      </b-button>
                      <b-popover
                        target="popover-target-2"
                        triggers="hover"
                        placement="bottom"
                      >
                        <template #title>Descuento</template>
                        <div class="text-center">
                          <h4>EGRESADO – UCPS</h4>
                          <p><b>Matricula S/. 120.00</b></p>
                          <p><b>Pensiones S/. 180.00</b></p>
                          <p><b>Sin descuento S/. 200.00</b></p>
                          <p>
                            Presentar una solicitud dirigida a UNIDAD DE
                            PRODUCCIÓN DE BIENES Y SERVICIOS (UCPS).
                          </p>
                          <p>
                            Indicar sus datos completos, nombre del programa de
                            especialización y turno en el cual se matriculó.
                          </p>
                          <p>
                            Adjuntar Copia de certificado general del programa
                            que egresó.
                          </p>
                          Enviarlo al correo: <b>fips_ucps@unsa.edu.pe</b>
                          <p>Asunto: Descuento alumno egresado UCPS</p>
                        </div>
                      </b-popover>
                    </div>
                  </b-col>
                  <b-col lg="4" sm="12" class="my-1">
                    <div class="text-center">
                      <b-button class="des-boton" id="popover-target-3">
                        Dos programas
                      </b-button>
                      <b-popover
                        target="popover-target-3"
                        triggers="hover"
                        placement="right"
                      >
                        <template #title>Descuento</template>
                        <div class="text-center">
                          <h4>DOS PROGRAMAS</h4>
                          <p><b>Matricula S/. 240.00 </b></p>
                          <p><b>Pensiones S/. 380.00 </b></p>
                          <p><b>Sin descuento S/. 400.00 </b></p>
                          <p>Cancelar las dos matriculas (S/.240.00)</p>
                          <p>Cancelar la primera pensión (S/. 340.00)</p>
                          <p>
                            Adjuntar los pagos en un solo archivo al formulario
                            de matricula y marcar los programas en que se ha
                            matriculado.
                          </p>
                          <p>
                            Ingresar al formulario de descuento dos programa y
                            registrarse para recibir el descuento en todas sus
                            pensiones
                          </p>
                          <p>
                            Esperar la confirmación del Área Academia de la UCPS
                            para que sea valido su descuento a partir de su
                            primera pensión.
                          </p>
                          <p>
                            <b
                              >Solo se aplica el descuento en el segundo
                              programa, el horario de los programas no pueden
                              cruzarse. matricula.
                            </b>
                          </p>
                        </div>
                      </b-popover>
                    </div>
                  </b-col>
                </b-row>
                <b
                  >Ingresar y seleccionar la información solicitada, debe subir
                  solo una imagen del pago completo.</b
                >
              </b-alert>
            </div>
            <div class="card">
              <div
                class="card-header"
                style="background-color: rgb(119, 35, 45)"
              >
                <h2><b-icon icon="journal-arrow-up" /> {{ lista.name }}</h2>
              </div>
              <div class="card-body">
                <b-form>
                  <FormInput
                    v-for="(dato, index) in lista.list"
                    :key="index"
                    :lista="dato"
                    ref="items"
                  />
                  <button
                    type="button"
                    style="
                      background-color: #1d425e;
                      border: none;
                      color: white;
                    "
                    class="btn form-control mt-2"
                    @click="send()"
                  >
                    Enviar
                  </button>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Mensaje alerta solicitud-->

    <div>
      <b-modal centered v-model="showSend" title="Mensaje">
        <b-container fluid>
          <!--Contenido modal-->
          <h5 class="text-center">¿Seguro que desea enviar el formulario?</h5>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="validateToken()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exitSend()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
    <!--Mensaje carga-->
    <b-toast id="my-toast" variant="info" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Mensaje!</strong>
        </div>
      </template>
      <h4>
        Porcentaje de carga <b>{{ messageValue }}</b> %
      </h4>
    </b-toast>
    <!---->
  </div>
</template>
<script>
import FormInput from "../../components/FormInput.vue";
export default {
  components: { FormInput },
  props: {
    idProgram: {
      type: String,
      default: "1",
    },
    idReference: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showSend: false,
      lista: {},
      selected: "",
      imageData: null,
      picture: null,
      messageValue: 0,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async sleep(msec) {
      return new Promise((resolve) => setTimeout(resolve, msec));
    },
    exitSend() {
      this.showSend = false;
    },
    send() {
      this.showSend = true;
    },

    //validar si el token esta activo
    async validateToken() {
      await this.$axios
        .get("/authentication-status", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then(() => {
          this.getInfo();
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async getInfo() {
      this.selected = "circular";
      this.showSend = false;
      var info = {
        id: this.lista.id,
        name: this.lista.name,
        list: [],
        reference: this.lista.reference,
      };
      for (var i = 0; i < this.lista.list.length; i++) {
        //Ingreso si es file
        if (this.lista.list[i].type == "file") {
          //Extension de la imagen
          if (this.$refs.items[i].getFileExtension(["JPG", "JPEG", "PNG"])) {
            this.$message.error(
              "Error de formato, subir imagenes jpg, jpeg o png"
            );
            this.initialize();
            return;
          }
          //Guardar imagen Firebase
          this.messageValue = 0;
          var dato = this.$refs.items[i].uploadImage();
          //Verificar porcentaje de subida de imagen
          while (true) {
            await this.sleep(250);
            this.messageValue = this.$refs.items[i].getUploadValue().toFixed();
            this.$bvToast.show("my-toast");
            if (this.$refs.items[i].getUploadValue() == 100) {
              console.log("Imagen subida");
              break;
            }
            if (this.$refs.items[i].getUploadError()) {
              this.$message.error("Error al subir imagen, vuelva a intentarlo");
              this.initialize();
              return;
              break;
            }
          }
          //Verificar si se ingreso imagen
          var tem = {};
          tem = this.$refs.items[i].salida();
          if (dato != null) {
            tem.label = dato;
          } else {
            this.$message.error("Error al subir imagen, vuelva a intentarlo");
            this.initialize();
            return;
          }
          info.list.push(tem);
        }
        //Validación de campos
        if (this.$refs.items[i].requerido() == true) {
          if (
            this.$refs.items[i].salida().label == null ||
            this.$refs.items[i].salida().label == ""
          ) {
            this.$message.error("Llene los campos requeridos");
            this.initialize();
            return;
          }
        }
        if (this.lista.list[i].type != "file")
          info.list.push(this.$refs.items[i].salida());
      }
      console.log(info);
      //Envio de datos
      this.saveItem(info);
    },
    async initialize() {
      await this.$axios
        .get(
          "/requisition?reqId=" + this.idProgram + "&refId=" + this.idReference,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.lista = res.data;
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
          //this.$message.error(e.response.data.content);
          //this.$router.push("/dashboard");
        });
    },
    async saveItem(info) {
      this.selected = "circular";
      await this.$axios
        .post("/requisition", info, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.$message.success(res.data.content);
          this.$router.push("/dashboard");
          this.selected = "";
          //this.initialize();
        })
        .catch((e) => {
          this.selected = "";
          this.$message.error(e.response.data.content);
        });
    },
  },
};
</script>
<style scoped>
.card-header {
  background-color: #1d425e;
  color: white;
}
</style>
<style scoped>
.des-boton {
  width: 100%;
}
.card-body {
  padding: 3% 3%;
}
</style>